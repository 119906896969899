export default [
  {
    title: 'Người lao động',
    icon: 'UserIcon',
    children: [
      {
        title: 'Danh mục nghề nghiệp',
        route: 'list-occupation',
      },
      {
        title: 'Bệnh nghề nghiệp',
        route: 'occupational-disease',
      },
      {
        title: 'Người lao động',
        route: 'manage-workers',
      },
      {
        title: 'Cấp phép người lao động',
        route: 'workerLicense',
      },
      {
        title: 'Báo cáo',
        route: 'work-report-list',
      },

    ],
  },
]
