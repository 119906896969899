// danh sách menu cho sở giáo dục

import user from '../user'
import business from '../business'
import workers from '../workers'
// import news from '../news'
import occupationalSafetyHealth from '../occupational-safety-health'
import service from '../service'
import employmentService from '../employment-service'
// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
export default [...user, ...business, ...workers, ...employmentService, ...occupationalSafetyHealth, ...service]
