export default [
  {
    title: 'Dịch vụ việc làm',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Cơ cấu tổ chức trung tâm dịch vụ việc làm',
        route: 'org-struct-employment',
      },
      {
        title: 'Phiên giao dịch việc làm',
        route: 'session-employment',
      },
      {
        title: 'Hoạt động tư vấn việc làm',
        route: 'advise-employment',
      },
      {
        title: 'Nhu cầu tuyển dụng của doanh nghiệp',
        route: 'demand-employment',
      },
      {
        title: 'Đăng ký tìm việc làm',
        route: 'register-employment',
      },
      {
        title: 'Hoạt động giới thiệu việc làm',
        route: 'recommend-employment',
      },
      {
        title: 'Tổ chức đào tạo, tập huấn',
        route: 'training-employment',
      },
      {
        title: 'Chính sách bảo hiểm thất nghiệp',
        route: 'insurance-employment',
      },
      {
        title: 'Kế hoạch dịch vụ việc làm',
        route: 'plan-employment',
      },
    ],
  },
]
