export default [
  {
    title: 'Thống kê',
    icon: 'BarChart2Icon',
    children: [
      {
        title: 'Toàn tỉnh trong độ tuổi lao động',
        route: 'statistical-worker-age',
      },
      {
        title: 'Toàn tỉnh theo ngành nghề',
        route: 'statistical-worker-career',
      },
      {
        title: 'Theo cơ cấu tuổi , giới tính',
        route: 'statistical-gender-structure',
      },
      {
        title: 'Toàn tỉnh đang trong tình trạng thất nghiệp',
        route: 'statistical-unemployment',
      },
      {
        title: 'Toàn tỉnh được đóng BHXH , BHYT  ',
        route: 'statistical-bhxh-bhyt',
      },
      {
        title: 'Toàn tỉnh theo mức thu nhập',
        route: 'statistical-income-level',
      },
      {
        title: 'Theo đơn vị sử dụng lao động',
        route: 'statistical-use-labor',
      },
      {
        title: 'Theo đơn vị hành chính cấp xã, huyện',
        route: 'statistical-administrative',
      },
      {
        title: 'Các trường hợp rủi ro , tai nạn lao động',
        route: 'statistical-accident',
      },
      {
        title: 'Đang làm việc tại nước ngoài',
        route: 'statistical-work-world',
      },
      {
        title: 'Đang làm việc tại Thái Nguyên',
        route: 'statistical-work-ThaiNguyen',
      },
      {
        title: 'Số người bị bệnh nghề nghiệp mới ',
        route: 'statistical-illness-new',
      },
      {
        title: 'Số người bị bệnh nghề nghiệp củ',
        route: 'statistical-illness-old',
      },
      {
        title: 'Chi phí cho người bệnh nghề nghiệp',
        route: 'statistical-cost-illness',
      },

    ],
  },
]
