export default [
  {
    title: 'Tin tức',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Quản lý tin tức',
        route: 'news-management',
      },
      {
        title: 'Chủ đề tin tức',
        route: 'news-topic',
      },
      {
        title: 'Hòm thư góp ý',
        route: 'suggestion-box',
      },
      {
        title: 'Văn bản pháp lý',
        route: 'legal-documents',
      },
    ],
  },
]
