export default [
  {
    title: 'Quản lý đơn vị liên kết',
    icon: 'Link2Icon',
    children: [
      {
        title: 'Đơn vị liên kết',
        route: 'connective-unit',
      },
      {
        title: 'Tài khoản khai thác',
        route: 'account-exploit',
      },
    ],
  },
]
