export default [
  {
    title: 'An toàn vệ sinh lao động',
    icon: 'ShieldIcon',
    children: [
      {
        title: 'Danh mục thiết bị kiểm định',
        route: 'equipment-management',
      },
      {
        title: 'Loại chấn thương',
        route: 'classification-list',
      },
      {
        title: 'Quản lý tai nạn lao động',
        route: 'accidentManagement',
      },
      {
        title: 'Cấp giấy chứng nhận kiểm định',
        route: 'inspection-safety',
      },
      {
        title: 'Cấp giấy chứng nhận huấn luyện',
        route: 'inspection-training',
      },
      {
        title: 'Báo cáo',
        route: 'inspection-report',
      },
      {
        title: 'Quan trắc môi trường',
        route: 'environmental-monitoring-department',
      },
    ],
  },
]
