export default [
  {
    title: 'Doanh nghiệp',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Thông tin doanh nghiệp',
        route: 'business-add-orther',
      },
      {
        title: 'Quản lý tài liệu ký số',
        route: 'manage-doc',
      },
    ],
  },
]
