<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <vuexy-logo class="brand-logo" />
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <h4 class="mb-2">
            Xác thực đăng nhập
          </h4>
          <span class="d-flex flex-column">
            <span>Một mã xác thực đã được gửi đến email
              <b-link>
                <span>{{ emailAuthen }}</span>
              </b-link></span>
            <span>Vui lòng nhập mã để tiến hành đăng nhập</span>
          </span>
          <!-- form -->
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="hanleLoginAuthen"
          >
            <b-form-group
              label-for="register-taxid"
            >
              <label for="label">Mã xác thực</label>
              <b-form-input
                id="register-taxid"
                v-model="dataInput.code"
                name="register-taxid"
                placeholder="Mã xác thực"
              />
            </b-form-group>
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Xác thực
            </b-button>
          </b-form>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import departmentMenu from '@/navigation/vertical/department-menu'
import businessMenu from '@/navigation/vertical/business-menu'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/login-image.svg'),
      required,
      email,
      emailAuthen: '',
      dataInput: {
        code: null,
        userName: JSON.parse(localStorage.getItem('authen_two_factor')).userName,
        cIp: JSON.parse(localStorage.getItem('authen_two_factor')).cIp,
      },
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-image.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.dataEmailUser(this.dataInput.userName)
  },
  methods: {
    // gọi API thông tin người dùng
    async dataEmailUser(userName) {
      const { data } = await axiosApiInstance.get(`/Login/get-email-by-user-name?userName=${userName}`)
      this.emailAuthen = data?.email
    },

    async hanleLoginAuthen() {
      const res = await axiosApiInstance.post('/Login/login-two-factor', this.dataInput).then(async response => {
        const token = response.data?.data || ''
        if (token) {
          localStorage.setItem(jwtDefaultConfig.storageTokenKeyName, `Bearer ${token}`)
        }
        await this.accountInfo()
        this.$ability.update([{ action: 'manage', subject: 'all' }])
        const urlParams = new URLSearchParams(window.location.search)

        if (this.roleUser === 'DepartmentOfEducation') {
          this.$router.push(urlParams.has('redirect') ? urlParams.get('redirect') : { name: 'dashboard-ecommerce' }).catch(() => {})
        } else if (this.roleUser === 'EducationDepartment') {
          this.$router.push(urlParams.has('redirect') ? urlParams.get('redirect') : { name: 'infomation-unit-education' }).catch(() => {})
        } else {
          this.$router.push(urlParams.has('redirect') ? urlParams.get('redirect') : { name: 'business-add-orther' }).catch(() => {})
        }
        this.loading = false
      }).catch(e => {
        this.$root.$bvToast.toast(e?.response?.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.loading = false
      })
    },
    // Lấy thông tin người dùng
    async accountInfo() {
      const { data } = await useJwt.accountInfo()
      if (data) {
        const userData = {
          ...data,
          ability: [{ action: 'manage', subject: 'all' }],
        }
        localStorage.setItem(jwtDefaultConfig.userData, JSON.stringify(userData))
        if (data?.roleIdentity) {
          this.roleUser = data?.roleIdentity
        }

        const dataMenu = await this.$store.dispatch('verticalMenu/getMenuByRole')
        localStorage.setItem('menuItems', JSON.stringify(dataMenu?.data))
        this.$store.commit('verticalMenu/UPDATE_MENU_ITEMS', dataMenu?.data)

        if (dataMenu?.data) {
          localStorage.setItem('menuItems', JSON.stringify(dataMenu?.data))
          this.$store.commit('verticalMenu/UPDATE_MENU_ITEMS', dataMenu?.data)
        } else {
          switch (data.roleIdentity.toUpperCase()) {
            case 'DEPARTMENTOFEDUCATION':
              localStorage.setItem('menuItems', JSON.stringify(departmentMenu))
              this.$store.commit('verticalMenu/UPDATE_MENU_ITEMS', departmentMenu)
              break
            case 'BUSSINESS':
              localStorage.setItem('menuItems', JSON.stringify(businessMenu))
              this.$store.commit('verticalMenu/UPDATE_MENU_ITEMS', businessMenu)
              break
            default:
              break
          }
        }
      }
      // const data = await this.$store.dispatch('users/accountInfo')
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
