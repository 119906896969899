export default [
  {
    title: 'Doanh nghiệp',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Doanh nghiệp',
        route: 'business',
      },
      {
        title: 'Loại hình doanh nghiệp',
        route: 'type-of-business',
      },
      {
        title: 'Ngành nghề kinh doanh',
        route: 'list-businessSector',
      },
      {
        title: 'Phân loại báo cáo',
        route: 'classify-report',
      },
      {
        title: 'Tình hình nộp báo cáo',
        route: 'status-report',
      },
      {
        title: 'Tài liệu ký số',
        route: 'digitally-signed',
      },
      // {
      //   title: 'Khai báo tai nan lao động',
      //   route: 'labor-accident',
      // },
      // {
      //   title: 'Đề nghị cấp phép kiểm định ATVSLĐ',
      //   route: 'inspection-license',
      // },
      // {
      //   title: 'Đề nghị huấn luyện kiểm định ATVSLĐ',
      //   route: 'offer-training',
      // },
      // {
      //   title: 'Chi phí ATVSLĐ',
      //   route: 'cost',
      // },
      // {
      //   title: 'Quản lý bồi dưởng bằng hiện vật',
      //   route: 'foster-management',
      // },
      // {
      //   title: 'Quan trắc môi trường lao động',
      //   route: 'environmental-monitoring',
      // },
      // {
      //   title: 'Quản lý thiết bị',
      //   route: 'device-management',
      // },
      // {
      //   title: 'Khai báo thiết bị',
      //   route: 'device-declaration',
      // },
      // {
      //   title: 'Kiểm định thiết bị',
      //   route: 'equipment-inspection',
      // },
      // {
      //   title: 'Phân loại sức khỏe',
      //   route: 'health-classification',
      // },
      // {
      //   title: 'Người lao động',
      //   route: 'woker',
      // },
    ],
  },
]
