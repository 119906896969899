export default [
  {
    title: 'Cài đặt',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Cài đặt',
        route: 'setting',
      },
      {
        title: 'Sắp xếp chức năng',
        route: 'custom-menu',
      },
    ],
  },
]
