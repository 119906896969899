// danh sách menu cho sở giáo dục

import user from '../user'
import business from '../business-deparment'
import workers from '../workers-department'
import statistical from '../statistical'
import news from '../news'
import profile from '../profile'
import setting from '../setting'
import occupationalSafetyHealth from '../occupational-safety-health-deparment'
import connective from '../connective'
import archive from '../archiveRecord'
import service from '../service-deparment'

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
export default [...user, ...business, ...workers, ...occupationalSafetyHealth, ...archive, ...news, ...statistical, ...service, ...setting]
