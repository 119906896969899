export default [
  {
    title: 'Hồ sơ lưu trữ',
    icon: 'FolderIcon',
    children: [
      {
        title: 'Quản lý hồ sơ lưu trữ',
        route: 'archive-record',
      },
      {
        title: 'Danh mục hồ sơ',
        route: 'category-file',
      },
    ],
  },
]
