export default [
  {
    title: 'An toàn vệ sinh lao động',
    icon: 'ShieldIcon',
    children: [
      {
        title: 'Khai báo tai nạn lao động',
        route: 'declare-accident',
      },
      {
        title: 'Đề nghị cấp phép kiểm định ATVSLĐ',
        route: 'licensing',
      },
      {
        title: 'Đề nghị huấn luyện kiểm định ATVSLĐ',
        route: 'training-atvsld',
      },
      {
        title: 'Thực hiện huấn luyện ATVSLĐ',
        route: 'perform-training',
      },
      {
        title: 'Tự huấn luyện',
        route: 'self-training',
      },
      {
        title: 'Chi phí ATVSLĐ',
        route: 'cost-atvsld',
      },
      {
        title: 'Quản lý bồi dưỡng bằng hiện vật',
        route: 'foster',
      },
      {
        title: 'Quan trắc môi trường lao động',
        route: 'environmental-monitoring',
      },
      {
        title: 'Quản lý thiết bị',
        route: 'manage-equip',
      },
      {
        title: 'Quản lý kiểm định viên',
        route: 'inspector',
      },
      {
        title: 'Khai báo thiết bị',
        route: 'declare-equipment',
      },
      {
        title: 'Kiểm định thiết bị',
        route: 'inspect-equipment',
      },
      {
        title: 'Đánh giá rủi ro',
        route: 'risk-evaluation',
      },
    ],
  },
]
