export default [
  {
    title: 'Dịch vụ làm việc',
    icon: 'PhoneIcon',
    children: [
      {
        title: 'Nộp báo cáo',
        route: 'employment-service',
      },
    ],
  },
]
